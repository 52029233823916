import {createStore} from "vuex";
import VuexPersistence from 'vuex-persist'
import api from '@/api';
import {showModal} from "@/modals";
import StepperFindLock from "@/components/StepperFindLock.vue";
import {normalizeQrCode} from "@/qr";

const vuexLocal = new VuexPersistence({
    storage: window.localStorage
})

const store = createStore({
    plugins: [vuexLocal.plugin],
    state() {
        return {
            app: null,
            settings: {
                padlocks: {
                    products: null
                }
            },
            selectedSiteId: null,
            user: null,
            token: null,
            sites: [],
            collectJobs: [],
            lockJobs: [],
            padlocks: []
        }
    },
    getters: {
        authenticated: state => {
            const tokenPresent = (typeof state.token !== 'undefined') && state.token != null;

            if (tokenPresent && !api.defaults.headers['Authorization']) {
                api.defaults.headers['Authorization'] = `Bearer ${state.token}`;
            }

            return tokenPresent;
        },
        token: (state, getters) => getters.authenticated ? null : state.token,
        user: (state, getters) => getters.authenticated ? null : state.user,
        app: state => state.app,
        settings: state => state.settings,
        sites: state => state.sites,
        mySites: state => {
            const enabledSites = ['storman', 'store365'].includes(state.settings.integration.type)
                ? state.sites.filter(s => s.integrationRubikEnabled)
                : state.sites;

            if (state.user.role !== 'manager') {
                return enabledSites;
            }

            return enabledSites
                .filter(s => state.user.siteIds.includes(s.id));
        },
        padlocks: state => state.padlocks,
        padlock: (state) => (qrCode) => {
            // intentionally tolerates comparing string w/ number
            const padlocks = state.padlocks.filter(p => p.qrCode == qrCode);
            return padlocks.length > 0 ? padlocks[0] : null;
        },
        selectedSiteId: state => state.selectedSiteId,
        collectJobs: state => state.collectJobs,
        collectJobsCount: (state, getters) => getters.collectJobs.length,
        lockJobs: state => state.lockJobs,
        lockJobsCount: (state, getters) => getters.lockJobs.length,
        productLinks: () => {
            return {
                adminLinkUrl: process.env.VUE_APP_ADMIN_LINK_URL,
                adminLinkLabel: process.env.VUE_APP_ADMIN_LINK_LABEL,
                websiteLinkUrl: process.env.VUE_APP_WEBSITE_LINK_URL,
                websiteLinkLabel: process.env.VUE_APP_WEBSITE_LINK_LABEL,
                supportEmailUrl: process.env.VUE_APP_SUPPORT_EMAIL_URL,
                supportEmailLabel: process.env.VUE_APP_SUPPORT_EMAIL_LABEL,
                contactFormLinkUrl: process.env.VUE_APP_CONTACT_FORM_LINK_URL,
                contactFormLinkLabel: process.env.VUE_APP_CONTACT_FORM_LINK_LABEL
            }
        }
    },
    mutations: {
        session(state, {user, token}) {
            state.user = user;
            state.token = token;

            api.defaults.headers['Authorization'] = `Bearer ${token}`;
        },
        logout(state) {
            state.user = null;
            state.token = null;

            delete api.defaults.headers['Authorization'];

            localStorage.clear();
        },
        reset(state) {
            state.selectedSiteId = null;
            state.user = null;
            state.token = null;
            state.sites = [];
            state.collectJobs = [];
            state.lockJobs = [];
            state.padlocks = [];
        },
        sites: (state, sites) => state.sites = sites,
        settings: (state, settings) => state.settings = settings,
        padlocks: (state, padlocks) => state.padlocks = padlocks,
        selectSite: (state, id) => state.selectedSiteId = id,
        collectJobs: (state, jobs) => state.collectJobs = jobs,
        lockJobs: (state, jobs) => state.lockJobs = jobs,
        app: (state, app) => state.app = app
    },
    actions: {
        async login(context, {email, password}) {
            context.commit('reset');

            const response = await api.post('/auth/login', {
                email: email,
                password: password
            });

            context.commit('session', {user: response.data.user, token: response.data.token});

            await context.dispatch('settings');
            await context.dispatch('loadPadlocks');
        },
        async settings(context) {
            const response = await api.get('/settings');
            context.commit('settings', response.data);
        },
        async loadSites(context) {
            const response = await api.get('/sites');
            const sites = response.data;

            if (sites.length === 0) {
                return;
            }

            context.commit('sites', sites);
            await context.dispatch('selectSite', context.getters.mySites[0].id);
        },
        async selectSite(context, id) {
            await context.commit('selectSite', id);
            await context.dispatch('loadJobs');
        },
        async loadPadlocks(context) {
            const response = await api.get('/padlocks');
            context.commit('padlocks', response.data);
        },
        async loadJobs(context) {
            await context.dispatch('loadCollectJobs');
            await context.dispatch('loadLockJobs');
            await context.dispatch('loadPadlocks');
        },
        async loadCollectJobs(context) {
            if (!context.state.selectedSiteId) {
                return;
            }

            const response = await api.get(`/collectPadlockJobs?state=executable_anytime&siteId=${context.state.selectedSiteId}`);
            context.commit('collectJobs', response.data);
        },
        async loadLockJobs(context) {
            if (!context.state.selectedSiteId) {
                return;
            }

            const response = await api.get(`/lockUnitJobs?state=waiting,executable_anytime,executable_urgent&siteId=${context.state.selectedSiteId}`);
            context.commit('lockJobs', response.data);
        },
        async addPadlock(context, {unlockCode, qrCode}) {
            await api.post('/padlocks', {
                unlockCode, qrCode
            });
            await context.dispatch('loadPadlocks');
        },
        async updateUnlockCode(context, {id, unlockCode}) {
            await api.patch(`/padlocks/${id}/unlockCode`, {unlockCode});
            await context.dispatch('loadPadlocks');
        },
        async getClaimableLock(context, qrCode) {
            return (await api.get(`/padlocks/claim/${qrCode}`)).data;
        },
        async claimPadlock(context, {qrCode}) {
            await api.post(`/padlocks/claim/${qrCode}`);
            await context.dispatch('loadPadlocks');
        },
        async reportDamage(context, {qrCode}) {
            await api.delete(`/padlocks/claim/${qrCode}`);
        },
        async executeCollectPadlockJob(context, {id}) {
            await api.put(`/collectPadlockJobs/${id}`);
            await context.dispatch('loadCollectJobs');
        },
        async executeDamageReport(context, {id}) {
            await api.post(`/collectPadlockJobs/report/${id}`);
            await context.dispatch('loadCollectJobs');
        },
        async executeLockUnitJob(context, {id, qrCode}) {
            await api.put(`/lockUnitJobs/${id}`, {
                padlockQrCode: qrCode
            });
            await context.dispatch('loadCollectJobs');
        },
        async executeSwapPadlockJob(context, {oldPadlockId, newPadlockId}) {
            await api.post('/swapPadlockJobs', {oldPadlockId, newPadlockId});
            await context.dispatch('loadPadlocks');
        },
        async executeRetirePadlockJob(context, {padlockId}) {
            await api.delete(`/padlocks/${padlockId}`);
            await context.dispatch('loadPadlocks');
        },
        async scanQrCode(context) {
            console.log('context.state.app: ', context.state.app);

            if (!context.state.app) {
                await showModal(StepperFindLock);
            }

            const processWrappedQrCode = (event) => {
                normalizeQrCode(event.detail);
                window.removeEventListener('onCodeEntered', processWrappedQrCode);
            }

            window.addEventListener('onCodeEntered', processWrappedQrCode);

            if (context.state.app === 'ios') {

                window.webkit.messageHandlers.codeSearch.postMessage('open');
            } else /* must be android */ {
                window.addEventListener('onCodeEntered', processWrappedQrCode);
                window.codeSearch.open();
            }
        }
    }
})

export default store
